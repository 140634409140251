import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import AuthorBox from '../components/AuthorBox'
import SEO from '../components/SEO'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  author,
  date,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <div className="is-flex is-flex-direction-row is-align-items-center">
              { author && <img class="is-rounded" src={author.avatar} alt={author.name} title={author.name} style={{ width: '30px', height: '30px', borderRadius: '15px', marginRight: '5px' }} /> }
              <p className="has-text-grey-dark">{author?.name} - {date}</p>
            </div>
            <br />
            <PostContent content={content} />
            <br />
            {author && 
              <AuthorBox author={author} />
            }
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                {tags.map((tag) => (
                  <Link to={`/tags/${kebabCase(tag)}/`} className="mr-2">
                    <span key={tag + `tag`} className="tag is-dark">
                      {tag}
                    </span>
                  </Link>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  
  const { markdownRemark: post } = data
  const slug = post.fields.slug

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <>
            <Helmet titleTemplate="%s - VITspot">
              <title>{`${post.frontmatter.title}`}</title>
            </Helmet>
            <SEO postPath={slug} postNode={post} postSEO />
          </>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        author={post.fields.author}
        date={post.frontmatter.date}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredImageUrl
        tags
      }
      fields {
        slug
        author {
          name
          headline
          description
          avatar
        }
      }
    }
  }
`
