const config = {
    siteTitle: "VITspot – #1 Digital Media in VIT", // Site title.
    siteTitleShort: "VITspot", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
    siteTitleAlt: "VITspot – #1 Digital Media in VIT", // Alternative site title for SEO.
    siteLogo: "/img/VITspot-logo.png", // Logo used for SEO and manifest.
    siteUrl: "https://vitspot.com", // Domain of your website without pathPrefix.
    pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
    siteDescription: "A one stop website for all VITians to get VIT News, VIT Clubs, VIT Resources, VIT Question Papers, CAT1 CAT2 FAT Papers, VITEEE Exam", // Website description used for RSS feeds/meta description tag.
    // siteRss: "/rss.xml", // Path to the RSS file.
    // siteRssTitle: "VITspot", // Title of the RSS feed
    siteFBAppID: "2113615738923335", // FB Application ID for using app insights
    googleAnalyticsID: "UA-110227844-1", // GA tracking ID.
    // disqusShortname: "https-vagr9k-github-io-gatsby-advanced-starter", // Disqus shortname.
    dateFromFormat: "MMMM DD, YYYY", // Date format used in the frontmatter.
    dateFormat: "DD/MM/YYYY", // Date format for display.
    // postsPerPage: 4, // Amount of posts displayed per listing page.
    // userName: "Advanced User", // Username to display in the author segment.
    // userEmail: "AdvancedUser@example.com", // Email used for RSS feed's author segment
    // userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
    // userLocation: "North Pole, Earth", // User location to display in the author segment.
    // userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
    // userDescription: "Yeah, I like animals better than people sometimes... Especially dogs. Dogs are the best. Every time you come home, they act like they haven't seen you in a year. And the good thing about dogs... is they got different dogs for different people.", // User description to display in the author segment.
    // // Links to social profiles/projects you want to display in the author segment/navigation bar.
    // userLinks: [{
    //         label: "GitHub",
    //         url: "https://github.com/Vagr9K/gatsby-advanced-starter",
    //         iconClassName: "fa fa-github",
    //     },
    //     {
    //         label: "Twitter",
    //         url: "https://twitter.com/Vagr9K",
    //         iconClassName: "fa fa-twitter",
    //     },
    //     {
    //         label: "Email",
    //         url: "mailto:vagr9k@gmail.com",
    //         iconClassName: "fa fa-envelope",
    //     },
    // ],
    // copyright: "Copyright © 2020. Advanced User", // Copyright string for the footer of the website and RSS feed.
    // themeColor: "#c62828", // Used for setting manifest and progress theme colors.
    // backgroundColor: "#e0e0e0", // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
    config.pathPrefix = "";
} else {
    // Make sure pathPrefix only contains the first forward slash
    config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
    config.siteUrl = config.siteUrl.slice(0, -1);

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== "/")
    config.siteRss = `/${config.siteRss}`;

module.exports = config;