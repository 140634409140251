import React from 'react'

const AuthorBox = ({ author }) => (
  <div class="card">
    <div class="card-content">
      <div class="columns">
        <div class="column is-narrow">
          <figure class="image is-128x128">
            <img class="is-rounded" src={author.avatar} alt={author.name} title={author.name} />
          </figure>
        </div>
        <div class="column">
          <p class="title is-4">{author.name}</p>
          <p class="subtitle is-6">{author.headline}</p>
          <p>{author.description}</p>
        </div>
      </div>
    </div>
  </div>
)

export default AuthorBox